import React from "react"
import { Paragraph, Link } from "evergreen-ui"
import StartButton from "../components/StartButton"

export default ({ location }) => {
  return (
    <>
      <Paragraph size={500} marginTop="default">
        Dear customer,
      </Paragraph>
      <Paragraph size={500} marginTop="default">
        Thank you for your interest to join the European Goodyear eCommerce
        installer network. As you know, the network serves consumers which
        purchased online through a Goodyear eCommerce website. Simultaneously,
        Goodyear ensures you with a market-competitive remuneration for the
        mounting services; in addition, Goodyear provides you with a tire
        compensation margin for each transaction. Please have a look at attached{" "}
        <Link href="/videos/en/video.mp4" target="_blank" size={500}>
          video
        </Link>{" "}
        and{" "}
        <Link href="#" size={500}>
          manual
        </Link>{" "}
        to enlarge your understanding of the program.
      </Paragraph>
      <Paragraph size={500} marginTop="default">
        To sign up for the program, please run through all steps of the online
        registration process.
      </Paragraph>

      <Paragraph size={500} marginTop="default">
        After finalizing the online registration, both Goodyear and you will get
        a copy of all data which you have submitted. As of this moment, Goodyear
        will evaluate your registration. After evaluation, Goodyear will inform
        you if you are activated as a mounting partner.
      </Paragraph>

      <Paragraph size={500} marginTop="default" marginBottom="default">
        Again, we thank you for your interest in joining the Goodyear eCommerce
        installer network. We hope to welcome you as a mounting partner.
      </Paragraph>
      <StartButton search={location.search} />
    </>
  )
}
